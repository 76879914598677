<template>
  <loading v-if="loading" />
  <form v-else @submit.prevent="$emit('next')">
    <p class="has-margin-bottom-150">
      If you already have a
      <em>first</em> task in mind let us know the details below; you'll be able
      to create additional tasks later on. If you aren't sure yet, you can skip
      this for now.
    </p>

    <b-field class="has-text-right">
      <button
        type="button"
        class="button is-grey is-outlined"
        tabindex="-1"
        @click="skip"
      >
        Skip for now
      </button>
    </b-field>

    <b-field label="Subject (optional)">
      <b-input
        v-model="form.taskSubject"
        type="text"
        placeholder="Enter a subject for this task"
      />
    </b-field>

    <b-field label="Task details *">
      <b-input
        v-model="form.taskDetails"
        :disabled="processing"
        :placeholder="task ? task.placeholder : 'How can we help?'"
        type="textarea"
        expanded
        required
        rows="6"
      />
    </b-field>

    <step-controls :enabled="formIsValid" @back="$emit('back')" />
  </form>
</template>

<script>
import { doc, getDoc } from "@firebase/firestore";
export default {
  name: "TaskDetailsStep",
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      task: null,
      form: {
        taskTypePath: this.payload.taskTypePath || null,
        taskDetails: this.payload.taskDetails || null,
        taskSubject: this.payload.taskSubject || null
      }
    };
  },
  computed: {
    formIsValid() {
      if (!this.form.taskTypePath) return false;
      if (!this.form.taskDetails) return false;
      return true;
    }
  },
  watch: {
    form: {
      handler(form) {
        this.$emit("payload", form);
      },
      deep: true
    }
  },
  created() {
    const firstTaskRef = doc(this.$firestore, `taskTypes/first-task`);
    getDoc(firstTaskRef).then(snapshot => {
      this.loading = false;
      if (!snapshot.exists()) return;
      this.task = snapshot.data();
      this.form.taskTypePath = snapshot.ref.path;
    });
  },
  methods: {
    skip() {
      this.$emit("payload", {
        taskTypePath: null,
        taskDetails: null,
        taskSubject: null
      });
      this.$emit("next");
    }
  }
};
</script>
